.container {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 48px;
}

.illustration {
    height: 300px;
}

.heading {
    margin-bottom: 18px;
}

.button {
    border: none;
    margin-top: var(--spacing-6);
    cursor: pointer;
}
