/* stylelint-disable css-modules/no-global-scoped-selector */
.root {
    --redesign-nav-height: 80px;

    /* Commons */
    --survey-border-radius: 4px;

    /* Question Editor */
    --survey-wizard-header-height: calc(var(--base-unit) * 20.25);
    --editor-main-width: calc(var(--base-unit) * 1.5 * 110);
    --meta-container-modifier: 43;
    --meta-container-width: calc(var(--base-unit) * var(--meta-container-modifier) * 1.5);
    --meta-container-offset: calc(var(--base-unit) * (var(--meta-container-modifier) + 4) * 1.5);

    /* Hosted Page */
    --hosted-survey-content-width: 600px;
    --hosted-survey-message-width: 350px;
    --hosted-survey-large-content-width: 1200px;
    --hosted-survey-footer-content-height: 40px;
    --hosted-survey-footer-height: 64px;

    --hosted-survey-content-bottom-whitespace: calc(var(--base-unit) * 25);
    --hosted-survey-message-minimum-height: calc(var(--base-unit) * 50);

    --survey-tiny-fs: 0.625em;

    /* Full height behavior */
    height: 100%;
    display: flex;
    flex-direction: column;
}
