body,
body > div:first-child {
    height: 100%;
    min-height: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

body {
    background-color: #f2f2f2;
}
